.message-display {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background: white;
}

.message-display h3 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: black;

    text-align: center;
}