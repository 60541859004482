.Application-Header {
  text-align: center;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.category-Field {
  display: flex;
  min-width: 50%;
  padding-bottom: 50px;
}

.Label {
  width: 100%;
  text-align: center
}

.valuesErrors {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  font-size: 12px;
}
