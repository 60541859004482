.flex-sub-Login {
  display: flex;
  flex-direction: column;
  resize: both;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(#05ACEE 21%, #015b7f);  /* For Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#05ACEE 21%, #015b7f);/* For Opera 11.1 to 12.0 */
  background: -moz-radial-gradient(#05ACEE 21%, #015b7f); /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.guru-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 30%;
}

.username {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: relative;
}

.password {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: relative;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signup-text {
  font-family: 'Oxygen';
  text-decoration: none;
}

.link-text {
  text-decoration: none;
  color: blue;
}

.requiredLocation_Brand {
  display: flex;
  flex-direction: row;
  position: absolute;
  /* transform: translateX(-50px); */
  left: -50px;
}

.requiredText_Brand {
  font-size: 40%;
  color: red;
  margin: 0;
  float: left;
}
