

.about-intro {
  background: #262626;
  padding-top: 3em;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.about-intro h1 {
  color: rgba(255,255,255,0.8);
}

.about-div {
  padding: 60px 5% 50px 5%;
}

.about h2 {
  margin-bottom: 50px;
  position: relative;
}

.about h2::after {
  content: '';
  height: 10px;
  width: 10px;
  background-color: #FFDA26;
  position: absolute;
  bottom: -18px;
  left: 3px;
}

.about p, .about li {
  color: #27393D;
  font-size: 18px;
  line-height: 1.8em;
}

.blue-bubble {
  background-color: #FFF;/*rgba(5,172,238,0.3);/*#FFF;#BFEFFF;*/
  border-radius: 10px;
  box-shadow: 0px 20px 40px 10px rgba(0,0,0,0.3);
}

.row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.aligned-end {
  align-items: flex-end;
}

.centered {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

#our-mission {
  background: #FAFAFA;
}

#our-team {
  background-color: #FFFFFF;/*#FDFCF2;*/
}

.team-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.team-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 20px 40px 10px rgba(0,0,0,0.3);
  max-width: 425px;
  margin-bottom: 60px;
  padding: 15px 10px 15px 10px;
}

.about .split-box {
  min-width: 297.5px;
}
.split-box {
  padding: 20px;
}

@media(min-width: 775px) {
  .split-box-left {
    border-right: 1px solid #AAA;
    border-bottom: none;
  }

  .split-box {
    width: calc(50% - 40.5px);
  }
}

@media(max-width: 774px) {
  .split-box-left {
    border-bottom: 1px solid #AAA;
    border-right: none;
  }

  .split-box {
    width: 100%;
  }
}

@media(min-width: 860px) {
  .team-card {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(50% - 80px);
  }
}

@media(max-width: 859px) {
  .team-card {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    min-width: 288px;
  }
}


.team-card div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 170.5px;
  width: 212.5px;
  text-align: center;
}

.team-card div h3 {
font-size: 20px;
}

.team-card img {
  width: 87.5px;
  height: 87.5px;
  object-fit: cover;
  border-radius: 43.75px;
  margin-right: 10px;
}
