.interact li {
  margin-bottom: 20px;
  font-weight: 300;
}

.dark-to-blue {
  color: rgba(255,255,255,0.8);
  background: linear-gradient(170deg, #262626, 90%,#05ACEE);
}

.blue-to-dark {
  color: rgba(255,255,255,0.9);
  background: linear-gradient(#05ACEE, 80%,#262626);
}

.white-to-blue {
  background: linear-gradient(#FFF, 75%, #05ACEE);
}

.embedded-instagram.rotated.rendered {
  transform: rotate(-4deg);
  position: relative;
  z-index: 0;
}

.embedded-instagram.rotated.rendered::after {
  content: '';
  background-color: #EEE;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(4deg);
  z-index: -1;
}

ol.interact li {
  transform: none;
  -webkit-transition: transform 200ms;
  transition:         transform 200ms;
}

ol.interact li:hover {
  transform: scale(1.05);
  -webkit-transition: transform 400ms;
  transition:         transform 400ms;
}
