.brand-logo {
width: auto;
padding: 5px;
margin: 20px;
}

.portal-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding-top: 3em;
  background: #262626;
}

.portal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 320px;
}

.portal-title {
  width: 306px;
  border-bottom: 2px solid #FFDA26;
  font-size: 36px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}
.portal-title>img {
  margin-right: 25px;
}

#portal-hook {
  font-size: 19px;
  margin: 5px 0px 15px 0px;
  color: white;
}

#portal-hook h2 {
  margin-top: 0;
  font-size: 19px;
}

#portal-hook a {
  font-size: 16px;
}

.portal-menu > label {
  margin: 20px 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-align: center;
  background: rgba(255,255,255,0.9);
  border-radius: 25px;
  padding: 5px;
}

#portal-grid {
  opacity: 0;
  transition: opacity 500ms;
}

#portal-grid.appear {
  opacity: 1;
  transition: opacity 500ms;
}

.grid-link {
  display: block;
  padding: 0;
  margin: 0;
  z-index:0;
  box-shadow: none;
  opacity: 1;
  transition: opacity 800ms, box-shadow 200ms, z-index 200ms;
}

.grid-link:hover {
  z-index:1;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
  transition: box-shadow 200ms, z-index 200ms;
}

.grid-link > img {

  border-radius: 5px;
  display: block;
  padding: 2.5px;
  margin: 0;
}

.grid-link.hidden {
  opacity: 0;
  transition: opacity 800ms;
}

#AppScreen {
  margin-top: 30px;
  height: 286px;
  border-radius: 5px;
  box-shadow:2px -2px 10px rgba(0,0,0,1);
}
