.intro-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 500px;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  /* transform: translateX(-100%); */
  transform: translateY(-25%);
  opacity: 0;
  border-bottom: 3px solid #FFDA26;
  overflow: hidden;
  z-index: 0;
  text-align: center;
}

.section-intro > p {
  font-size: 32px;
}


.intro-subheader {
  margin-top: 20px;
  transform: translateY(-25%);
  z-index: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 600px;
  max-width: 100%;
  opacity: 0;
  text-align: center;
}

.intro-close-text {
  transform: translateY(-25%);
  z-index: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 75%;
  max-width: 90%;
  text-align: center;
  margin: 25px 10px 40px 10px;
  padding: 10px 10px 10px 10px;
  opacity: 0;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.6);
  border-radius: 50px;
  transition: box-shadow 200ms;
  text-decoration: none;
  color: black;
}

#brand-link {
  width: 270px;
}

#guru-link {
  width: 195px;
}

.intro-close-text:hover {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.6);
  transition: box-shadow 200ms;
  cursor: pointer;
}

.appear {
  transform: translateY(0);
  transform: translateZ(0);
  opacity: 1;
  transition: opacity 0.5s, transform 1s;
}



.intro-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 4em 0 1em 0;
  background: #FFF;
}

.section-intro {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  max-width: 100%;
  margin: 0 20px 0 20px;
  overflow-x: hidden;
}

#brand-intro-img {
  height: 100%;
  max-width: 100%;
}
