#caption {
  margin-left: 40px;
}

.deals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.deal_block {
  height: 600px;
  width: 300px;
  border-radius: 10px;
  background: #FAFAFA;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deal_arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.deal_arrows div {
  margin: 10px;
}

.deal_arrows svg:hover {
  cursor:pointer;
}

.deal_block img {
  max-height: 100%;
  max-width: 100%;
  padding: 20px;
}

.deal_block img:hover {
  cursor:pointer;
}

#tip {
  margin: 50px;
}
