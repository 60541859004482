.bm-burger-button {
  width: 30px;
  height: 30px;
  position: relative;
  right: 30px;
  bottom: 0.5em;
  padding-bottom: 25%;
}

.bm-menu {
    background: rgba(0,0,0,0.90);
    border-style: solid;
    border-width: 1px 0px 0px 1px;
    border-color: #05ACEE;
    border-radius: 3px;
    padding-right: 0;
    margin-right: 0;
}

.bm-cross {
    background: #FFDA26;
}

.bm-item-list {
  margin-top: 50px;
}

.bm-item {
  display: inline-block;
  text-align: center;
  color: #FFDA26;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  padding-top: 15px;
  /* font-size: 66%; */
}

.bm-item-active {
  color: #05ACEE;
}

.bm-item:active {
  color: #05ACEE;
  background: none;
}
