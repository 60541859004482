.flex-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(#05ACEE 21%, #015b7f);  /* For Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#05ACEE 21%, #015b7f);/* For Opera 11.1 to 12.0 */
  background: -moz-radial-gradient(#05ACEE 21%, #015b7f); /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.landing-text {
  font-family: 'Oxygen';
}

.landing-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-brand {
  margin-right: 10px;
  width: 70px;
  text-align: center;
  border: 2px solid #2d91bf;
}

.button-brand:hover {
  background-color: #848d91;
  color: white;
}

.button-influencer {
  margin-left: 10px;
  width: 70px;
  text-align: center;
  border: 2px solid #2d91bf;
}

.button-influencer:hover {
  background-color: #848d91;
  color: white;
}
