.DealListItem__Title {
  font-weight: 600;
}

.DealListItem__Post {
  display: flex;
  align-items: center;
}

.DealListItem__Post > *:not(:first-child)::before {
  content: '•';
  margin: 0 4px;
  color: #919eab; /* ink, lightest */
}

.DealListItem__Tip {
  white-space: nowrap;
  margin: 0 10px;
}

.DealListItem__Likes {
  display: flex;
  min-width: 0;
  justify-content: flex-end;
  font-weight: 600;
  margin: 0 4px;
}

@media (min-width: 640px) {
  .DealListItem__Main {
    display: flex;
  }

  .DealListItem__Main {
    margin-right: 20px;
  }

  .DealListItem__Profile {
    align-items: center;
  }

  .DealListItem__Post {
    flex: 1;
    justify-content: flex-end;
  }

  .DealListItem__Post > *:not(:first-child)::before {
    display: none;
  }


  .DealListItem__Likes {
    min-width: 100px;
  }
}

@media(min-width: 800px) {
  .DealListItem__Profile {
    display: flex;
    flex-wrap: wrap;
  }

  .DealListItem__Profile > *:not(:last-child) {
    margin-right: 12px;
  }
}

.DealListItem a {
  color: inherit;
}