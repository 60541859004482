p.reset {
  font-family: 'Montserrat', sans-serif;
}

#reset_submit {
  background: rgba(255,255,255,0.85);
  font-family: 'Montserrat', sans-serif;
  color: rgba(0,0,0,0.85);
  margin: 10px;
  text-decoration: none;
  padding: 10px;
}

#reset_submit:hover{
  cursor:pointer;
}

input.reset {
  margin: 10px;
}

