.previewDiv {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.previewDiv>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
