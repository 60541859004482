.no-backgr {
  --shopify-gray: #F4F6F8;
  background-color: var(--shopify-gray);
  min-height: 100%;
}

.sandbox-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-link {
  border: none;
  background: none;
  color: blueviolet;
  font-weight: bold;
}

.button-link:hover {
  cursor: pointer;
  color: violet;
}