@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500|Raleway:500);
html, body, #root, #root > div {
  height: 100%;
  width: 100%;
}

.wrap {
  font-family: 'Montserrat', sans-serif;

}

.App {
  text-align: center;
}

.light-padding {
  padding: 12px
}

.gold {
  background-color: #FFDA26;
  color: rgba(0,0,0,0.8);
}

.blue {
  background-color: #05ACEE;
  color: rgba(0,0,0,0.8);
}
/*
h1 {
  font-weight: 300;
  font-size: 40px;
}

h2 {
  font-weight: 300;
  font-size: 24px;
}

h3 {
  font-weight: 400;
  font-size: 24px;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  color: #1392c4;
}*/

.gray {
  background-color: #FAFAFA;
}

.dark {
  background-color: #262626;
  color: rgba(255,255,255,0.8);
}

.white {
  background-color: #FFF;
  color: black;
}

.mid-height {
  min-height: 400px;
}

.tall {
  min-height: 600px;
}

.column {
  display: flex;
  flex-direction: column;
}

.room-up-top {
  padding-top: 3em;
}

.align-center {
  align-items: center;
    text-align: center;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  list-style-position: inside;
}

.align-start {
  align-items: flex-start;
}

.space-around {
  justify-content: space-around;
}

.flex-start {
  justify-content: flex-start;
}

.stretch-width {
  width: 100%;
}

.contain-width {
  width: 95%;
}

.stretch-height {
  height: 100%;
}

.center {
  text-align: center;
}

.link-button {
  border-radius: 5px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.3);
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.link-button:hover {
  box-shadow: 0px 4px 10px 2.8px rgba(0,0,0,0.7);
}

.link-button.dark {
  background-color: #262626;
  color: #EAF5F9;
}

.link-button.light {
  background-color: #FAFAFA;
  color: #262626;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  min-height: 100px;
  height: 20%;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.convoRow {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-width: 4px;
  border-style: solid;
  border-radius: 15px;
  border-color: transparent;
}

.convoRow:hover {
  cursor:pointer;
}

.convoRow img, .convoRow div {
  height: 300px; width: 300px;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.convo-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px 0 15px 0;
  }

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.flex-sub-Login {
  display: flex;
  flex-direction: column;
  resize: both;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */  /* For Safari 5.1 to 6.0 *//* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.guru-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 30%;
}

.username {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: relative;
}

.password {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: relative;
}

.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.signup-text {
  font-family: 'Oxygen';
  text-decoration: none;
}

.link-text {
  text-decoration: none;
  color: blue;
}

.requiredLocation_Brand {
  display: flex;
  flex-direction: row;
  position: absolute;
  /* transform: translateX(-50px); */
  left: -50px;
}

.requiredText_Brand {
  font-size: 40%;
  color: red;
  margin: 0;
  float: left;
}

.flex-landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */  /* For Safari 5.1 to 6.0 *//* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.landing-text {
  font-family: 'Oxygen';
}

.landing-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-brand {
  margin-right: 10px;
  width: 70px;
  text-align: center;
  border: 2px solid #2d91bf;
}

.button-brand:hover {
  background-color: #848d91;
  color: white;
}

.button-influencer {
  margin-left: 10px;
  width: 70px;
  text-align: center;
  border: 2px solid #2d91bf;
}

.button-influencer:hover {
  background-color: #848d91;
  color: white;
}

.wrap,.wrap>div {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 375px;
}

.intro {
	width: 100%;
	margin: 0;
	padding: 0;
	background-size: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
  position: fixed;
  top: 0;
  z-index: 10;
	align-content: space-between;
}

.intro .topbar {
	width: 100%;
	height: 72px;
	display: flex;
	flex-direction: row;
  align-items: flex-end;
  background: #262626;/*rgba(0,0,0,1.00);*/
  /*box-shadow: 0px 0px 4px 0px rgb(255,218,38);/*rgba(5,172,238,1);*/
  transition: box-shadow 500ms 0ms, height 400ms;
}

.topbar.add-shadow {
  height: 2.5em;
  box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.25);
  transition: box-shadow 500ms 0ms, height 400ms;
}

.title-group {
	display: flex;
	flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title-group label:hover {
  cursor: pointer;
}

.title {
	margin: 0px 16px 0 16px;
  font-size: 110%;
  text-shadow: 0px 0px rgba(1, 1, 1, 0.3);
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.title a {
  /*color: rgba(255,255,255,0.75);/*#05ACEE; #4F95A5;*/
  color: #05ACEE;
  text-decoration: none;
}

.nav-menu {
  margin-left: 50px;
}

.nav-menu label  {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
	height: 100%;
  font-size: 75%;
  line-height: 3.5;
  margin: 0px 48px 0px 48px;
}

.light-link {
  text-decoration: none;
  color: rgba(255,255,255,0.8);
  padding: 0 0 2px 0;
  margin: 0px 20px 3px 20px;
  border-color: rgba(0,0,0, 0);
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  background: none;
  transition: border-color 200ms, color 200ms/*, background 200ms;*/
}

.light-link:hover {
  color: #05ACEE;
  transition: color 200ms;
}

.light-link.active-link {
  border-color: rgba(5, 172, 238, 1);
  color: #05ACEE;
  transition: border-color 500ms 0ms, color 500ms;
}

.main {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height:90%;
	width: 100%;
}

.guru {
	width: 100%;
	height: 30%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


#guru-icon {
	height: 100%;
	display: block;
	margin: 0;
	padding: 0;
  pointer-events: none;
}

#guru-icon:hover {
	cursor: pointer;
}

.download {
	height: 15%;
	width: 100%;
	min-height:60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#download-image {
	height: 100%;
	margin: 10px;
}

#download-link {
	height: 66%;
}

html, body {
	/* margin: 0;
	padding: 0;

	/*min-height: 581px;
	width: 100%;
	font-size: 24px;
	overflow-x: hidden; */
}

.main-centered {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  /* background: #0B0F1A; */
  background: #F7F7F7;
}

.vertical-center-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.horizontal-start-aligned {
  padding: 95px 0px 95px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  /* background: #AACED7;/*rgba(170,206,215,0.7);*/
}

.vertical-start-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* max-width: 100%; */
  background:  #40BFF5;/*#AACED7;*/
  width: 300px;
  height: 275px;
  padding: 20px 0 5px 0;
  margin: 25px 0 25px 0;
  border-radius: 5px;
  /* border-radius: 20px; */
  box-shadow: 10px 20px 40px 0px rgba(0,0,0,0.3);
  /* box-shadow: -5px -5px 0px 0px rgba(255,255,255,1); */

}

.horizontal-start-aligned>a {
  max-width: 100%;
  text-align: center;
}

.section-image {
  max-width: 100%;
  /* box-shadow: -5px -5px 0px rgba(255,255,255,1); */
  box-shadow: 10px 20px 40px 0px rgba(0,0,0,0.3);

  /* border-radius: 5px; */
  transition: box-shadow 200ms;
  margin: 20px;
}

a>.section-image:hover {
  box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
  transition: box-shadow 200ms;
}

.section-description {
  max-width: 90%;
  width: 500px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 20px;
  border-width: 3px 0 0px 0;
  border-color: #FFDA26; /*rgba(255,218,38,0.5);*/
  border-style: solid;
}

.section-header {
  margin: 0 0px 5px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 500px;
  max-width: 90%;
  text-align: center;
  color: rgba(255,255,255,0.95);
  /* color: #4F95A5; */
}

.section-subheader {
  margin: 5px 0px 25px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 500px;
  max-width: 90%;
  text-align: center;
  color: rgba(255,255,255,0.9);
  /* color: #4F95A5; */
}

.heavy {
  font-weight: 500;
}

.survey-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
  background: #262626;
}

.flex-sub {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */  /* For Safari 5.1 to 6.0 *//* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.firstColumn {
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: flex-end;
  padding-right: 10px;
  position: relative;
}

.lastColumn {
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: flex-start;
  padding-left: 10px;
  position: relative;
}

.Field {
  display: flex;
  color: #bfbfbf;
  padding-bottom: 20px;
  position:relative;
}

.country {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
  margin: none;
}

.requiredLocation_Signup {
  display: flex;
  flex-direction: row;
  position: absolute;
  /* transform: translateX(-50px); */
  top: -13px;
}

.requiredLocation_Signup_Country {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -3px;
}

.requiredText_Signup {
  font-size: 40%;
  color: red;
  margin: 0;
  float: left;
}

.Application-Header {
  text-align: center;
  text-decoration: underline;
}

.flex-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.category-Field {
  display: flex;
  min-width: 50%;
  padding-bottom: 50px;
}

.Label {
  width: 100%;
  text-align: center
}

.valuesErrors {
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  font-size: 12px;
}

.bm-burger-button {
  width: 30px;
  height: 30px;
  position: relative;
  right: 30px;
  bottom: 0.5em;
  padding-bottom: 25%;
}

.bm-menu {
    background: rgba(0,0,0,0.90);
    border-style: solid;
    border-width: 1px 0px 0px 1px;
    border-color: #05ACEE;
    border-radius: 3px;
    padding-right: 0;
    margin-right: 0;
}

.bm-cross {
    background: #FFDA26;
}

.bm-item-list {
  margin-top: 50px;
}

.bm-item {
  display: inline-block;
  text-align: center;
  color: #FFDA26;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  padding-top: 15px;
  /* font-size: 66%; */
}

.bm-item-active {
  color: #05ACEE;
}

.bm-item:active {
  color: #05ACEE;
  background: none;
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  margin-top: 70px;
}

.section-title {
  font-family: 'Oxygen', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px 0;
text-align: center;
}

.info-section-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info-section-detail img {
  max-width: 250px;
  min-width: 250px;
  max-height: 175px;
  min-height: 175px;
  border-radius: 10px;
}

.info-section-detail p {
  font-family: 'Oxygen', sans-serif;
  font-size: 18px;
  width: 250px;
  margin-left: 25px;
}

.bulletin-board {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
  align-items: center;
}

.bulletin-sheet {
  min-width: 250px;
  max-width: 737.5px;
  margin: 50px 31.25px 50px 31.25px;
  padding: 0px 31.25px 50px 31.25px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.intro-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 500px;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  /* transform: translateX(-100%); */
  transform: translateY(-25%);
  opacity: 0;
  border-bottom: 3px solid #FFDA26;
  overflow: hidden;
  z-index: 0;
  text-align: center;
}

.section-intro > p {
  font-size: 32px;
}


.intro-subheader {
  margin-top: 20px;
  transform: translateY(-25%);
  z-index: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 600px;
  max-width: 100%;
  opacity: 0;
  text-align: center;
}

.intro-close-text {
  transform: translateY(-25%);
  z-index: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 75%;
  max-width: 90%;
  text-align: center;
  margin: 25px 10px 40px 10px;
  padding: 10px 10px 10px 10px;
  opacity: 0;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.6);
  border-radius: 50px;
  transition: box-shadow 200ms;
  text-decoration: none;
  color: black;
}

#brand-link {
  width: 270px;
}

#guru-link {
  width: 195px;
}

.intro-close-text:hover {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.6);
  transition: box-shadow 200ms;
  cursor: pointer;
}

.appear {
  transform: translateY(0);
  transform: translateZ(0);
  opacity: 1;
  transition: opacity 0.5s, transform 1s;
}



.intro-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 4em 0 1em 0;
  background: #FFF;
}

.section-intro {
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  max-width: 100%;
  margin: 0 20px 0 20px;
  overflow-x: hidden;
}

#brand-intro-img {
  height: 100%;
  max-width: 100%;
}

.brand-logo {
width: auto;
padding: 5px;
margin: 20px;
}

.portal-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding-top: 3em;
  background: #262626;
}

.portal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 320px;
}

.portal-title {
  width: 306px;
  border-bottom: 2px solid #FFDA26;
  font-size: 36px;
  margin-bottom: 30px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
}
.portal-title>img {
  margin-right: 25px;
}

#portal-hook {
  font-size: 19px;
  margin: 5px 0px 15px 0px;
  color: white;
}

#portal-hook h2 {
  margin-top: 0;
  font-size: 19px;
}

#portal-hook a {
  font-size: 16px;
}

.portal-menu > label {
  margin: 20px 0 20px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-align: center;
  background: rgba(255,255,255,0.9);
  border-radius: 25px;
  padding: 5px;
}

#portal-grid {
  opacity: 0;
  transition: opacity 500ms;
}

#portal-grid.appear {
  opacity: 1;
  transition: opacity 500ms;
}

.grid-link {
  display: block;
  padding: 0;
  margin: 0;
  z-index:0;
  box-shadow: none;
  opacity: 1;
  transition: opacity 800ms, box-shadow 200ms, z-index 200ms;
}

.grid-link:hover {
  z-index:1;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.6);
  transition: box-shadow 200ms, z-index 200ms;
}

.grid-link > img {

  border-radius: 5px;
  display: block;
  padding: 2.5px;
  margin: 0;
}

.grid-link.hidden {
  opacity: 0;
  transition: opacity 800ms;
}

#AppScreen {
  margin-top: 30px;
  height: 286px;
  border-radius: 5px;
  box-shadow:2px -2px 10px rgba(0,0,0,1);
}

.contact-us-wrapper {
  margin-top: 3em;
  padding-bottom: 3em;
  width: 100%;
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
}

.contact-us {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  max-width: 100%;
}

#google-map {
  max-width: 100%;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

}

.follow-us {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 90%;
}

.contact-us-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-align: center;
}

.find-us {
  max-width: 90%;
}

#google-map-caption {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 400px;
  max-width: 100%;
  text-align: center;
}

.follow-us-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  margin: 20px 0px 20px 0px;
}

.name-field {
  width: 48%;
}

.contact-us-row {
  width: 100%;
  max-width: 888px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.message-us {
  width: 100%;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

label {
  font-size: 60%;
  margin-bottom: 8px;
}

input, select {
  height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 60%;
}

textArea {
  height: 100px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  resize: none;
  padding-top: 8px;
}

input[type="text"], input[type="password"], textArea, select {
  background: #ECF0F1;
  border: #056C96 solid 1px;
  padding-left: 8px;
}

input[type="submit"].primary {
  background: #05ACEE;
  color: #FFF;
  border: none;
  width: 90px;
  height: 40px;
  transition: background 50ms, color 50ms;
}

input[type="submit"].primary:hover {
  cursor: pointer;
  background: #82D8FC;
  transition: background 50ms;
}

input[type="submit"].secondary {
  background: #FFF;
  color: #000;
  border: solid;
  border-width: 1px;
  border-color: #000;
  width: 190px;
  height: 40px;
  transition: background 50ms, color 50ms;
}

input[type="submit"].secondary:hover {
  cursor: pointer;
  background: #F2F2F2;
  transition: background 50ms;
}

.submit-row {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
}

input[type="submit"].loading {
  background: none;
  color: #000;
  transition: background 50ms, color 50ms;
}

#fields-required {
  font-family: 'Montserrat';
  font-size: 14px;
  opacity: 0;
  height: 0;
  transition: height 500ms, opacity 500ms;
}

#fields-required.show {
  opacity: 1;
  height: 3em;
  transition: height 500ms, opacity 500ms;
}

form#message-us-form {
  padding: 0 20px 0 20px;
  opacity: 1;
  transition: height 500ms, opacity 500ms;
}

form#message-us-form.hidden {
  height: 0;
  opacity: 0;
  transition: height 500ms, opacity 500ms;
}

#success-message {
  width: 100%;
  margin: none;
  padding: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.instagram-media {
    opacity: 0;
    transition: opacity 200ms;
}

.instagram-media.instagram-media-rendered {
  opacity:1;
  transition:         opacity 3000ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.embedded-instagram {
  min-width: 326px;
  padding:0;
  width: 99.375%;
  width: calc(100% - 2px);
  opacity: 0;
  transition:         all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.embedded-instagram.rendered {
  opacity: 1;
  transition:         all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

#embedHtml {
  max-width: 540px;
}

#tournyHtml {
  max-width: 542px;
}

@media(min-width: 542px) {
  #lauraHtml {
    height: 1089px;
  }
  #embedHtml {
    height:723px;
  }
}

.previewDiv {
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.previewDiv>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

#rebate-field-wrapper {
    width: 100px;
    flex: 0 0 auto;
}

#rebate-row {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    text-align: right;
}

#rebate-row>p {
    margin-left: 20px;
}
.message-display {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    background: white;
}

.message-display h3 {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;

    color: black;

    text-align: center;
}
#rebate-limit-wrapper {
    width: 120px;
    margin-top:12px;
}
.DealListItem__Title {
  font-weight: 600;
}

.DealListItem__Post {
  display: flex;
  align-items: center;
}

.DealListItem__Post > *:not(:first-child)::before {
  content: '•';
  margin: 0 4px;
  color: #919eab; /* ink, lightest */
}

.DealListItem__Tip {
  white-space: nowrap;
  margin: 0 10px;
}

.DealListItem__Likes {
  display: flex;
  min-width: 0;
  justify-content: flex-end;
  font-weight: 600;
  margin: 0 4px;
}

@media (min-width: 640px) {
  .DealListItem__Main {
    display: flex;
  }

  .DealListItem__Main {
    margin-right: 20px;
  }

  .DealListItem__Profile {
    align-items: center;
  }

  .DealListItem__Post {
    flex: 1 1;
    justify-content: flex-end;
  }

  .DealListItem__Post > *:not(:first-child)::before {
    display: none;
  }


  .DealListItem__Likes {
    min-width: 100px;
  }
}

@media(min-width: 800px) {
  .DealListItem__Profile {
    display: flex;
    flex-wrap: wrap;
  }

  .DealListItem__Profile > *:not(:last-child) {
    margin-right: 12px;
  }
}

.DealListItem a {
  color: inherit;
}
#caption {
  margin-left: 40px;
}

.deals {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.deal_block {
  height: 600px;
  width: 300px;
  border-radius: 10px;
  background: #FAFAFA;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.deal_arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.deal_arrows div {
  margin: 10px;
}

.deal_arrows svg:hover {
  cursor:pointer;
}

.deal_block img {
  max-height: 100%;
  max-width: 100%;
  padding: 20px;
}

.deal_block img:hover {
  cursor:pointer;
}

#tip {
  margin: 50px;
}

.gallery {
    white-space: nowrap;
}
.gallery-item>img, .gallery-item>video { 
    width: 320px;
    height: 568.88px;
}

.gallery-item .info {
    width: 320px;
    height: 100px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery-item {
    display: inline-flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    background: #f9fafb;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)
}

.gallery-item-action-row {
    display: flex;
    width: 100%;
    justify-content: center;
}

/* .gallery-item-tip-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} */
.no-backgr {
  --shopify-gray: #F4F6F8;
  background-color: var(--shopify-gray);
  min-height: 100%;
}

.sandbox-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.button-link {
  border: none;
  background: none;
  color: blueviolet;
  font-weight: bold;
}

.button-link:hover {
  cursor: pointer;
  color: violet;
}
.interact li {
  margin-bottom: 20px;
  font-weight: 300;
}

.dark-to-blue {
  color: rgba(255,255,255,0.8);
  background: linear-gradient(170deg, #262626, 90%,#05ACEE);
}

.blue-to-dark {
  color: rgba(255,255,255,0.9);
  background: linear-gradient(#05ACEE, 80%,#262626);
}

.white-to-blue {
  background: linear-gradient(#FFF, 75%, #05ACEE);
}

.embedded-instagram.rotated.rendered {
  transform: rotate(-4deg);
  position: relative;
  z-index: 0;
}

.embedded-instagram.rotated.rendered::after {
  content: '';
  background-color: #EEE;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: rotate(4deg);
  z-index: -1;
}

ol.interact li {
  transform: none;
  transition:         transform 200ms;
}

ol.interact li:hover {
  transform: scale(1.05);
  transition:         transform 400ms;
}

.case-study-prop img {
  object-fit: cover;
}

.case-study p {
  font-size: 20px;
}

.case-study-company {
  border-radius: 10px;
  margin: 9.25px;
  width: 150px;
  height: 80px;
}

.case-study-company:hover {
  cursor: pointer;
}

#company-select {
  width: 337.5px;
}

#company-select p:hover {
  color: #05ACEE;
  cursor: pointer;
}

#company-select p {
  font-size: 16px;
  color: #333;
  font-weight: 300;
  margin: 0;
}

#company-select p.active {
  font-weight: 400;
  color: #05ACEE;
}

.testimonial {
  border-radius: 15px;
  background-color: #FAFAFA;
}

.testimonial>p {
  width: 100%;
}

.testimonial>.quote {
  color: #333;
  font-style: italic;
}

.case-study-prop img.dormant {
  width: 245px;
  height: 245px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);
  margin: 50px 20px 0px 20px;
}

.case-study-prop img.active {
  border-radius: none;
  box-shadow: none;
  width: 285px;
  height: 285px;
  margin: 0px 0px 0px 0px;
}


.case-study-prop {
  height: 365px;
  margin: 0px 26.25px 0px 26.25px;
  border-radius: 5px;
}

.case-study-prop:hover {
  cursor: pointer;
}

.case-study-prop.dormant {
  box-shadow: none;
}

.case-study-prop.active {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.25);
}

.case-study-prop .text-div {
  height: 30px;
  width: calc(100% - 24px);
  display: flex;
  flexDirection: row;
  align-items: center;
  margin: 0px 12px 0px 12px;
}

.case-study-prop p {
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  font-weight: 200;
  font-family: 'Raleway', sans-serif;
}

.testimonial {
  padding: 12px;
  margin-top: 20px;
  min-height: 200px;
}

@media(max-width: 1025px) {
  .testimonial {
    min-height: 224px;
  }
}

@media(max-width: 800px) {
  .testimonial {
    min-height: 280px;
  }
}

@media(max-width: 510px) {
  .testimonial {
    min-height: 392px;
  }
}

.cs-tran.show {
  opacity: 1;
  transition: opacity 200ms;
}

.cs-tran.hide {
  opacity: 0;
  transition: opacity 200ms;
}

#brand-video {
  width: 500px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.5);
}

#discover-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  text-align: center;
    
}

#integrates-with-shopify::after {
  content: '';
  background-color: #FFF;/*rgba(5,172,238,0.8);*/
  position: absolute;
  z-index: -1;
  left: 67.5px;
  top: -49px;
  height: 200px;
  width: 200px;
  border-radius: 100px;
}

#integrates-with-shopify {
  font-size: 28px;
  width: 170px;
  padding: 0px 83.75px 0px 83.75px;
  z-index: 0;
  position: relative;
  text-align: center;
  margin: 53.75px 0px 80px 0px;
  display: inline-block;
}

#plugin-screenshot {
  max-width: 800px;
  width: calc(100% - 337.5px);
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 3px rgba(0,0,0,0.5);
}

#integrate-block {
  display: inline-flex;
}

#shopify-block {
  display: flex;
}

@media(min-width: 1301px) {
  #integrate-block {
    flex-direction: column;
    width: 337.5px;
  }

  #shopify-block {
    flex-direction: row;
    min-height: 600px;
  }
}

@media(max-width: 1300px) and (min-width: 768px) {
  #integrate-block {
    flex-direction: row;
    width: 675px;
  }

  #shopify-block {
    flex-direction: column;
  }

  #plugin-screenshot {
    width: 100%;
  }
}

@media(max-width: 767px) {
  #plugin-screenshot {
    display: none;
  }

  #integrate-block {
    flex-direction: column;
    width: 337.5px;
  }
}

#shopify-logo {
  object-fit: contain;
  width: 200px;
  margin: 0px 68.75px 0px 68.75px;
}

@media(min-width: 1200px) {
  #brand-intro-div {
    margin: 50px 0px 0px 0px;
  }

  #brand-video-container {
    margin: 0px 75px 0px 75px;
  }

  #discover-text {
    /*width: calc(50% - 150px);*/

  }
}

@media(max-width: 1199px) {

  #brand-intro-div {
    margin: 0px 0px 12.5px 0px;
  }

  #discover-text {
    /*width: 100%;
    align-items: center;
    text-align: center;
    list-style-position: inside;*/
  }

  #discover-text ul {
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
  }

  #brand-video-container {
    margin: 0;
  }
}

.datum-display {
    margin: 10px 30px 10px 30px
}

.datum-display p{
  margin: 5px;
}

.brand-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  margin: 20px;
  height: 306px;
  background: #333;
  padding: 0 10px 0 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.2);
}

.brand-div>p {
  font-size: .78em;
}

.brand-div>p>h5 {
  font-size: 1.2em;
  margin: 0;
}

#main_download_link {
  width: 200px;
  max-width: 90%;
  background: white;
  color: rgba(0,0,0,0.85);
  border-radius: 5px;
  text-decoration: none;
  padding: 15px;
  font-weight: 500;
}
  
.review {
  box-shadow: 0px 4px 10px 3px rgba(0,0,0,0.5);
  opacity: 0;
  border-radius: 10px;
  margin: 2.66%;
  transition: opacity 1600ms;
}

.review.show {
  opacity: 1;
  transition: opacity 1600ms;
}



.about-intro {
  background: #262626;
  padding-top: 3em;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.about-intro h1 {
  color: rgba(255,255,255,0.8);
}

.about-div {
  padding: 60px 5% 50px 5%;
}

.about h2 {
  margin-bottom: 50px;
  position: relative;
}

.about h2::after {
  content: '';
  height: 10px;
  width: 10px;
  background-color: #FFDA26;
  position: absolute;
  bottom: -18px;
  left: 3px;
}

.about p, .about li {
  color: #27393D;
  font-size: 18px;
  line-height: 1.8em;
}

.blue-bubble {
  background-color: #FFF;/*rgba(5,172,238,0.3);/*#FFF;#BFEFFF;*/
  border-radius: 10px;
  box-shadow: 0px 20px 40px 10px rgba(0,0,0,0.3);
}

.row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.aligned-end {
  align-items: flex-end;
}

.centered {
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

#our-mission {
  background: #FAFAFA;
}

#our-team {
  background-color: #FFFFFF;/*#FDFCF2;*/
}

.team-display {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.team-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  background: white;
  box-shadow: 0px 20px 40px 10px rgba(0,0,0,0.3);
  max-width: 425px;
  margin-bottom: 60px;
  padding: 15px 10px 15px 10px;
}

.about .split-box {
  min-width: 297.5px;
}
.split-box {
  padding: 20px;
}

@media(min-width: 775px) {
  .split-box-left {
    border-right: 1px solid #AAA;
    border-bottom: none;
  }

  .split-box {
    width: calc(50% - 40.5px);
  }
}

@media(max-width: 774px) {
  .split-box-left {
    border-bottom: 1px solid #AAA;
    border-right: none;
  }

  .split-box {
    width: 100%;
  }
}

@media(min-width: 860px) {
  .team-card {
    margin-left: 20px;
    margin-right: 20px;
    width: calc(50% - 80px);
  }
}

@media(max-width: 859px) {
  .team-card {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    min-width: 288px;
  }
}


.team-card div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-width: 170.5px;
  width: 212.5px;
  text-align: center;
}

.team-card div h3 {
font-size: 20px;
}

.team-card img {
  width: 87.5px;
  height: 87.5px;
  object-fit: cover;
  border-radius: 43.75px;
  margin-right: 10px;
}

p.reset {
  font-family: 'Montserrat', sans-serif;
}

#reset_submit {
  background: rgba(255,255,255,0.85);
  font-family: 'Montserrat', sans-serif;
  color: rgba(0,0,0,0.85);
  margin: 10px;
  text-decoration: none;
  padding: 10px;
}

#reset_submit:hover{
  cursor:pointer;
}

input.reset {
  margin: 10px;
}


.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  height: 300px;
  width: 317px;
  color: white;
}

select:hover {
  cursor: pointer;
}

.admin {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.admin a {
  text-decoration: none;
}

.admin button {
  text-decoration: none;
  background: none;
  border: none;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.tab {
  color: rgba(175,175,175,1);
  margin: 20px;
}

.tab:hover {
  color: white;
  cursor: pointer;
}

.tab.selected {
  color: white;
}

.admin tr {
  height: 55px;
}

.admin tbody tr.clickable:hover {
  background: rgba(50,50,50,1);
  cursor:pointer;
}

.admin td {
  text-align: center;
  max-width: 270px;
}



.admin.highlighted {
  color: #05ACEE;
}

.admin.reject {
  color: red
}

.sold {
  color: #ffbc29;
}

.admin button:hover {
  cursor: pointer;
  color: #AAA;
}

.admin a:hover {
  cursor: pointer;
  color: white;
}

.admin button:disabled {
  color: #777;
  cursor: auto;
}

.admin img {
  object-fit: contain;
  background: gray;
}

.admin input, .admin label {
  height: 30px;
  font-size: 100%;
}

.success {
  color: #0cfc2c;
}

#products_table td {
  padding: 10px;
}

