.contact-us-wrapper {
  margin-top: 3em;
  padding-bottom: 3em;
  width: 100%;
  display: flex;
  direction: row;
  justify-content: center;
  align-items: center;
  background: #FAFAFA;
}

.contact-us {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  max-width: 100%;
}

#google-map {
  max-width: 100%;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);

}

.follow-us {
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 90%;
}

.contact-us-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  text-align: center;
}

.find-us {
  max-width: 90%;
}

#google-map-caption {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  width: 400px;
  max-width: 100%;
  text-align: center;
}

.follow-us-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  margin: 20px 0px 20px 0px;
}

.name-field {
  width: 48%;
}

.contact-us-row {
  width: 100%;
  max-width: 888px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.message-us {
  width: 100%;
}

.form-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

label {
  font-size: 60%;
  margin-bottom: 8px;
}

input, select {
  height: 30px;
  font-family: 'Montserrat', sans-serif;
  font-size: 60%;
}

textArea {
  height: 100px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  resize: none;
  padding-top: 8px;
}

input[type="text"], input[type="password"], textArea, select {
  background: #ECF0F1;
  border: #056C96 solid 1px;
  padding-left: 8px;
}

input[type="submit"].primary {
  background: #05ACEE;
  color: #FFF;
  border: none;
  width: 90px;
  height: 40px;
  transition: background 50ms, color 50ms;
}

input[type="submit"].primary:hover {
  cursor: pointer;
  background: #82D8FC;
  transition: background 50ms;
}

input[type="submit"].secondary {
  background: #FFF;
  color: #000;
  border: solid;
  border-width: 1px;
  border-color: #000;
  width: 190px;
  height: 40px;
  transition: background 50ms, color 50ms;
}

input[type="submit"].secondary:hover {
  cursor: pointer;
  background: #F2F2F2;
  transition: background 50ms;
}

.submit-row {
  display: flex;
  flex-direction: horizontal;
  justify-content: space-between;
}

input[type="submit"].loading {
  background: none;
  color: #000;
  transition: background 50ms, color 50ms;
}

#fields-required {
  font-family: 'Montserrat';
  font-size: 14px;
  opacity: 0;
  height: 0;
  transition: height 500ms, opacity 500ms;
}

#fields-required.show {
  opacity: 1;
  height: 3em;
  transition: height 500ms, opacity 500ms;
}

form#message-us-form {
  padding: 0 20px 0 20px;
  opacity: 1;
  transition: height 500ms, opacity 500ms;
}

form#message-us-form.hidden {
  height: 0;
  opacity: 0;
  transition: height 500ms, opacity 500ms;
}

#success-message {
  width: 100%;
  margin: none;
  padding: none;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}
