.info-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 250px;
  margin-top: 70px;
}

.section-title {
  font-family: 'Oxygen', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 20px 0;
text-align: center;
}

.info-section-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.info-section-detail img {
  max-width: 250px;
  min-width: 250px;
  max-height: 175px;
  min-height: 175px;
  border-radius: 10px;
}

.info-section-detail p {
  font-family: 'Oxygen', sans-serif;
  font-size: 18px;
  width: 250px;
  margin-left: 25px;
}

.bulletin-board {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2em;
  align-items: center;
}

.bulletin-sheet {
  min-width: 250px;
  max-width: 737.5px;
  margin: 50px 31.25px 50px 31.25px;
  padding: 0px 31.25px 50px 31.25px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
