#rebate-field-wrapper {
    width: 100px;
    flex: 0 0 auto;
}

#rebate-row {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
    align-items: center;
    text-align: right;
}

#rebate-row>p {
    margin-left: 20px;
}