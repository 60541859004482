.gallery-item>img, .gallery-item>video { 
    width: 320px;
    height: 568.88px;
}

.gallery-item .info {
    width: 320px;
    height: 100px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallery-item {
    display: inline-flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    background: #f9fafb;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15)
}

.gallery-item-action-row {
    display: flex;
    width: 100%;
    justify-content: center;
}

/* .gallery-item-tip-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
} */