@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500|Raleway:500');

.wrap,.wrap>div {
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 375px;
}

.intro {
	width: 100%;
	margin: 0;
	padding: 0;
	background-size: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
  position: fixed;
  top: 0;
  z-index: 10;
	align-content: space-between;
}

.intro .topbar {
	width: 100%;
	height: 72px;
	display: flex;
	flex-direction: row;
  align-items: flex-end;
  background: #262626;/*rgba(0,0,0,1.00);*/
  /*box-shadow: 0px 0px 4px 0px rgb(255,218,38);/*rgba(5,172,238,1);*/
  transition: box-shadow 500ms 0ms, height 400ms;
}

.topbar.add-shadow {
  height: 2.5em;
  box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.25);
  transition: box-shadow 500ms 0ms, height 400ms;
}

.title-group {
	display: flex;
	flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title-group label:hover {
  cursor: pointer;
}

.title {
	margin: 0px 16px 0 16px;
  font-size: 110%;
  text-shadow: 0px 0px rgba(1, 1, 1, 0.3);
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
}

.title a {
  /*color: rgba(255,255,255,0.75);/*#05ACEE; #4F95A5;*/
  color: #05ACEE;
  text-decoration: none;
}

.nav-menu {
  margin-left: 50px;
}

.nav-menu label  {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
	height: 100%;
  font-size: 75%;
  line-height: 3.5;
  margin: 0px 48px 0px 48px;
}

.light-link {
  text-decoration: none;
  color: rgba(255,255,255,0.8);
  padding: 0 0 2px 0;
  margin: 0px 20px 3px 20px;
  border-color: rgba(0,0,0, 0);
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  background: none;
  transition: border-color 200ms, color 200ms/*, background 200ms;*/
}

.light-link:hover {
  color: #05ACEE;
  transition: color 200ms;
}

.light-link.active-link {
  border-color: rgba(5, 172, 238, 1);
  color: #05ACEE;
  transition: border-color 500ms 0ms, color 500ms;
}

.main {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height:90%;
	width: 100%;
}

.guru {
	width: 100%;
	height: 30%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}


#guru-icon {
	height: 100%;
	display: block;
	margin: 0;
	padding: 0;
  pointer-events: none;
}

#guru-icon:hover {
	cursor: pointer;
}

.download {
	height: 15%;
	width: 100%;
	min-height:60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

#download-image {
	height: 100%;
	margin: 10px;
}

#download-link {
	height: 66%;
}

html, body {
	/* margin: 0;
	padding: 0;

	/*min-height: 581px;
	width: 100%;
	font-size: 24px;
	overflow-x: hidden; */
}

.main-centered {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  /* background: #0B0F1A; */
  background: #F7F7F7;
}

.vertical-center-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.horizontal-start-aligned {
  padding: 95px 0px 95px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  /* background: #AACED7;/*rgba(170,206,215,0.7);*/
}

.vertical-start-aligned {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* max-width: 100%; */
  background:  #40BFF5;/*#AACED7;*/
  width: 300px;
  height: 275px;
  padding: 20px 0 5px 0;
  margin: 25px 0 25px 0;
  border-radius: 5px;
  /* border-radius: 20px; */
  box-shadow: 10px 20px 40px 0px rgba(0,0,0,0.3);
  /* box-shadow: -5px -5px 0px 0px rgba(255,255,255,1); */

}

.horizontal-start-aligned>a {
  max-width: 100%;
  text-align: center;
}

.section-image {
  max-width: 100%;
  /* box-shadow: -5px -5px 0px rgba(255,255,255,1); */
  box-shadow: 10px 20px 40px 0px rgba(0,0,0,0.3);

  /* border-radius: 5px; */
  transition: box-shadow 200ms;
  margin: 20px;
}

a>.section-image:hover {
  box-shadow: 0px 0px 20px rgba(0,0,0,0.6);
  transition: box-shadow 200ms;
}

.section-description {
  max-width: 90%;
  width: 500px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  margin-bottom: 30px;
  text-align: center;
  padding-top: 20px;
  border-width: 3px 0 0px 0;
  border-color: #FFDA26; /*rgba(255,218,38,0.5);*/
  border-style: solid;
}

.section-header {
  margin: 0 0px 5px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 500px;
  max-width: 90%;
  text-align: center;
  color: rgba(255,255,255,0.95);
  /* color: #4F95A5; */
}

.section-subheader {
  margin: 5px 0px 25px 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  width: 500px;
  max-width: 90%;
  text-align: center;
  color: rgba(255,255,255,0.9);
  /* color: #4F95A5; */
}

.heavy {
  font-weight: 500;
}

.survey-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0px 20px 0px;
  background: #262626;
}
