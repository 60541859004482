.case-study-prop img {
  object-fit: cover;
}

.case-study p {
  font-size: 20px;
}

.case-study-company {
  border-radius: 10px;
  margin: 9.25px;
  width: 150px;
  height: 80px;
}

.case-study-company:hover {
  cursor: pointer;
}

#company-select {
  width: 337.5px;
}

#company-select p:hover {
  color: #05ACEE;
  cursor: pointer;
}

#company-select p {
  font-size: 16px;
  color: #333;
  font-weight: 300;
  margin: 0;
}

#company-select p.active {
  font-weight: 400;
  color: #05ACEE;
}

.testimonial {
  border-radius: 15px;
  background-color: #FAFAFA;
}

.testimonial>p {
  width: 100%;
}

.testimonial>.quote {
  color: #333;
  font-style: italic;
}

.case-study-prop img.dormant {
  width: 245px;
  height: 245px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.25);
  margin: 50px 20px 0px 20px;
}

.case-study-prop img.active {
  border-radius: none;
  box-shadow: none;
  width: 285px;
  height: 285px;
  margin: 0px 0px 0px 0px;
}


.case-study-prop {
  height: 365px;
  margin: 0px 26.25px 0px 26.25px;
  border-radius: 5px;
}

.case-study-prop:hover {
  cursor: pointer;
}

.case-study-prop.dormant {
  box-shadow: none;
}

.case-study-prop.active {
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.25);
}

.case-study-prop .text-div {
  height: 30px;
  width: calc(100% - 24px);
  display: flex;
  flexDirection: row;
  align-items: center;
  margin: 0px 12px 0px 12px;
}

.case-study-prop p {
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  font-weight: 200;
  font-family: 'Raleway', sans-serif;
}

.testimonial {
  padding: 12px;
  margin-top: 20px;
  min-height: 200px;
}

@media(max-width: 1025px) {
  .testimonial {
    min-height: 224px;
  }
}

@media(max-width: 800px) {
  .testimonial {
    min-height: 280px;
  }
}

@media(max-width: 510px) {
  .testimonial {
    min-height: 392px;
  }
}

.cs-tran.show {
  opacity: 1;
  transition: opacity 200ms;
}

.cs-tran.hide {
  opacity: 0;
  transition: opacity 200ms;
}

#brand-video {
  width: 500px;
  max-width: 100%;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.5);
}

#discover-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  text-align: center;
    
}

#integrates-with-shopify::after {
  content: '';
  background-color: #FFF;/*rgba(5,172,238,0.8);*/
  position: absolute;
  z-index: -1;
  left: 67.5px;
  top: -49px;
  height: 200px;
  width: 200px;
  border-radius: 100px;
}

#integrates-with-shopify {
  font-size: 28px;
  width: 170px;
  padding: 0px 83.75px 0px 83.75px;
  z-index: 0;
  position: relative;
  text-align: center;
  margin: 53.75px 0px 80px 0px;
  display: inline-block;
}

#plugin-screenshot {
  max-width: 800px;
  width: calc(100% - 337.5px);
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 3px rgba(0,0,0,0.5);
}

#integrate-block {
  display: inline-flex;
}

#shopify-block {
  display: flex;
}

@media(min-width: 1301px) {
  #integrate-block {
    flex-direction: column;
    width: 337.5px;
  }

  #shopify-block {
    flex-direction: row;
    min-height: 600px;
  }
}

@media(max-width: 1300px) and (min-width: 768px) {
  #integrate-block {
    flex-direction: row;
    width: 675px;
  }

  #shopify-block {
    flex-direction: column;
  }

  #plugin-screenshot {
    width: 100%;
  }
}

@media(max-width: 767px) {
  #plugin-screenshot {
    display: none;
  }

  #integrate-block {
    flex-direction: column;
    width: 337.5px;
  }
}

#shopify-logo {
  object-fit: contain;
  width: 200px;
  margin: 0px 68.75px 0px 68.75px;
}

@media(min-width: 1200px) {
  #brand-intro-div {
    margin: 50px 0px 0px 0px;
  }

  #brand-video-container {
    margin: 0px 75px 0px 75px;
  }

  #discover-text {
    /*width: calc(50% - 150px);*/

  }
}

@media(max-width: 1199px) {

  #brand-intro-div {
    margin: 0px 0px 12.5px 0px;
  }

  #discover-text {
    /*width: 100%;
    align-items: center;
    text-align: center;
    list-style-position: inside;*/
  }

  #discover-text ul {
    padding-inline-start: 0px;
  }

  #brand-video-container {
    margin: 0;
  }
}

.datum-display {
    margin: 10px 30px 10px 30px
}

.datum-display p{
  margin: 5px;
}

.brand-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  margin: 20px;
  height: 306px;
  background: #333;
  padding: 0 10px 0 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.2);
}

.brand-div>p {
  font-size: .78em;
}

.brand-div>p>h5 {
  font-size: 1.2em;
  margin: 0;
}

#main_download_link {
  width: 200px;
  max-width: 90%;
  background: white;
  color: rgba(0,0,0,0.85);
  border-radius: 5px;
  text-decoration: none;
  padding: 15px;
  font-weight: 500;
}
  
.review {
  box-shadow: 0px 4px 10px 3px rgba(0,0,0,0.5);
  opacity: 0;
  border-radius: 10px;
  margin: 2.66%;
  transition: opacity 1600ms;
}

.review.show {
  opacity: 1;
  transition: opacity 1600ms;
}
