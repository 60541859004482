.instagram-media {
    opacity: 0;
    transition: opacity 200ms;
}

.instagram-media.instagram-media-rendered {
  opacity:1;
  -webkit-transition: opacity 3000ms cubic-bezier(0.39, 0.575, 0.565, 1);
  transition:         opacity 3000ms cubic-bezier(0.39, 0.575, 0.565, 1);
}

.embedded-instagram {
  min-width: 326px;
  padding:0;
  width: 99.375%;
  width: -webkit-calc(100% - 2px);
  width: calc(100% - 2px);
  opacity: 0;
  -webkit-transition: all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition:         all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.embedded-instagram.rendered {
  opacity: 1;
  -webkit-transition: all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
  transition:         all 800ms cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

#embedHtml {
  max-width: 540px;
}

#tournyHtml {
  max-width: 542px;
}

@media(min-width: 542px) {
  #lauraHtml {
    height: 1089px;
  }
  #embedHtml {
    height:723px;
  }
}
