.flex-sub {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #05ACEE; /* For browsers that do not support gradients */
  background: -webkit-radial-gradient(#05ACEE 21%, #015b7f);  /* For Safari 5.1 to 6.0 */
  background: -o-radial-gradient(#05ACEE 21%, #015b7f);/* For Opera 11.1 to 12.0 */
  background: -moz-radial-gradient(#05ACEE 21%, #015b7f); /* For Firefox 3.6 to 15 */
  background: radial-gradient(#05ACEE 31%, #00435e);
}

.firstColumn {
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: flex-end;
  padding-right: 10px;
  position: relative;
}

.lastColumn {
  display: flex;
  flex-direction: column;
  width: 34%;
  align-items: flex-start;
  padding-left: 10px;
  position: relative;
}

.Field {
  display: flex;
  color: #bfbfbf;
  padding-bottom: 20px;
  position:relative;
}

.country {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
  margin: none;
}

.requiredLocation_Signup {
  display: flex;
  flex-direction: row;
  position: absolute;
  /* transform: translateX(-50px); */
  top: -13px;
}

.requiredLocation_Signup_Country {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -3px;
}

.requiredText_Signup {
  font-size: 40%;
  color: red;
  margin: 0;
  float: left;
}
