.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  height: 300px;
  width: 317px;
  color: white;
}

select:hover {
  cursor: pointer;
}
