html, body, #root, #root > div {
  height: 100%;
  width: 100%;
}

.wrap {
  font-family: 'Montserrat', sans-serif;

}

.App {
  text-align: center;
}

.light-padding {
  padding: 12px
}

.gold {
  background-color: #FFDA26;
  color: rgba(0,0,0,0.8);
}

.blue {
  background-color: #05ACEE;
  color: rgba(0,0,0,0.8);
}
/*
h1 {
  font-weight: 300;
  font-size: 40px;
}

h2 {
  font-weight: 300;
  font-size: 24px;
}

h3 {
  font-weight: 400;
  font-size: 24px;
}

h4 {
  font-weight: 500;
  font-size: 20px;
  color: #1392c4;
}*/

.gray {
  background-color: #FAFAFA;
}

.dark {
  background-color: #262626;
  color: rgba(255,255,255,0.8);
}

.white {
  background-color: #FFF;
  color: black;
}

.mid-height {
  min-height: 400px;
}

.tall {
  min-height: 600px;
}

.column {
  display: flex;
  flex-direction: column;
}

.room-up-top {
  padding-top: 3em;
}

.align-center {
  align-items: center;
    text-align: center;
  padding-inline-start: 0px;
  list-style-position: inside;
}

.align-start {
  align-items: flex-start;
}

.space-around {
  justify-content: space-around;
}

.flex-start {
  justify-content: flex-start;
}

.stretch-width {
  width: 100%;
}

.contain-width {
  width: 95%;
}

.stretch-height {
  height: 100%;
}

.center {
  text-align: center;
}

.link-button {
  border-radius: 5px;
  box-shadow: 0px 4px 10px 2px rgba(0,0,0,0.3);
  text-decoration: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
}

.link-button:hover {
  box-shadow: 0px 4px 10px 2.8px rgba(0,0,0,0.7);
}

.link-button.dark {
  background-color: #262626;
  color: #EAF5F9;
}

.link-button.light {
  background-color: #FAFAFA;
  color: #262626;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  min-height: 100px;
  height: 20%;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.convoRow {
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-width: 4px;
  border-style: solid;
  border-radius: 15px;
  border-color: transparent;
}

.convoRow:hover {
  cursor:pointer;
}

.convoRow img, .convoRow div {
  height: 300px; width: 300px;
  border-radius: 10px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.convo-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px 0 15px 0;
  }

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
